import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  logoHt: {
    maxHeight: '40px',
    margin: '0 6px',
  },
});

class Insurance extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="center">
        <Grid
          item
          container
          alignContent="center"
          alignItems="center"
          justify="center"
          lg={9}
          md={10}
          xs={12}
          spacing={3}
        >
          <Grid container item justify="center">
            <Grid item xs={12}>
              <Typography
                gutterBottom
                style={{ textAlign: 'center', marginTop: '1em' }}
                variant="h2"
              >
                We Accept Most Insurance
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ textAlign: 'center' }}>
                While Reinhard Dentistry accepts most insurance, we are also now
                "In Network" with the following:
              </Typography>
            </Grid>
            <Grid
              style={{
                textAlign: 'center',
                marginTop: '1em',
                marginBottom: '1em',
              }}
              item
            >
              <Typography style={{ textAlign: 'center' }}>
                Aetna PPO
                <br />
                Ameritas
                <br />
                Cigna PPO
                <br />
                Delta Dental
                <br />
                Guardian
                <br />
                Humana
                <br />
                Metlife PDP
                <br />
                Principal
                <br />
                United Concordia PPO
                <br />
                United Healthcare
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ textAlign: 'center', marginBottom: '2em' }}>
                Please contact us with any questions.
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              item
              lg={8}
              md={10}
              xs={12}
              spacing={2}
              alignItems="center"
            >
              <Grid item>
                <img
                  className={classes.logoHt}
                  height="30px"
                  alt="aetna logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1571956263/reinhard-dentistry/insurance-logos/aetna.png"
                />
              </Grid>
              <Grid alignItems>
                <img
                  style={{ marginTop: '-8px' }}
                  height="36px"
                  className={classes.logoHt}
                  alt="ameritas logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1572023694/reinhard-dentistry/insurance-logos/ameritas.png"
                />
              </Grid>
              <Grid item>
                <img
                  className={classes.logoHt}
                  alt="cigna logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1571956264/reinhard-dentistry/insurance-logos/cigna.png"
                />
              </Grid>
              <Grid item>
                <img
                  className={classes.logoHt}
                  height="34px"
                  alt="delta logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1571956263/reinhard-dentistry/insurance-logos/Delta-Dental.png"
                />
              </Grid>
              <Grid item>
                <img
                  className={classes.logoHt}
                  alt="delta logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1571956264/reinhard-dentistry/insurance-logos/guardian.png"
                />
              </Grid>
              <Grid item>
                <img
                  height="26px"
                  className={classes.logoHt}
                  alt="humana logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1571956263/reinhard-dentistry/insurance-logos/humana.png"
                />
              </Grid>
              <Grid item>
                <img
                  className={classes.logoHt}
                  height="30px"
                  alt="metlife logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1571956264/reinhard-dentistry/insurance-logos/metlife.png"
                />
              </Grid>
              <Grid item>
                <img
                  className={classes.logoHt}
                  height="38px"
                  alt="principal logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1572023119/reinhard-dentistry/insurance-logos/Prin_new_Color-01.png"
                />
              </Grid>
              <Grid item>
                <img
                  className={classes.logoHt}
                  height="20px"
                  alt="united concordia logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1572023119/reinhard-dentistry/insurance-logos/United-Concordia.png"
                />
              </Grid>
              <Grid item>
                <img
                  className={classes.logoHt}
                  height="38px"
                  alt="united healthcare logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1589824861/reinhard-dentistry/insurance-logos/UHC-Logo-Color.png"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Insurance);
