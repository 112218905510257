import React, { Component } from 'react';
import { Icon, Typography, Grid, Divider } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  logoHt: {
    maxHeight: '40px',
  },
  icon: {
    color: 'rgba(0,0,0,.5)',
    margin: '.16em',

    fontSize: '1.75em  ',
  },
  li: {
    marginLeft: '2rem',
  },
});

class Covid extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="center">
        <Grid
          item
          container
          alignContent="center"
          alignItems="center"
          justify="center"
          lg={9}
          md={10}
          xs={12}
          spacing={5}
        >
          <Grid style={{ marginTop: '2rem' }} item>
            <Typography gutterBottom>
              <b>Dear Patients,</b>
            </Typography>
            <br />
            <Typography gutterBottom>
              Our community has been through a lot over the last few months, and
              all of us are looking forward to resuming our normal habits and
              routines. While many things have changed, one thing has remained
              the same: our commitment to your safety.
            </Typography>
            <br />
            <Typography gutterBottom>
              Infection control has always been a top priority for our practice.
              Our infection control processes are made so that when you receive
              care, it’s both safe and comfortable. We want to tell you about
              the infection control procedures we follow in our practice to keep
              patients and staff safe.
            </Typography>
            <br />
            <Typography gutterBottom>
              Our office follows infection control recommendations made by the
              American Dental Association (ADA), the U.S. Centers for Disease
              Control and Prevention (CDC) and the Occupational Safety and
              Health Administration (OSHA). We follow the activities of these
              agencies so that we are up-to-date on any new rulings or guidance
              that may be issued. We do this to make sure that our infection
              control procedures are current and adhere to each agencies’
              recommendations.
            </Typography>
            <br />
            <Typography gutterBottom>
              You may see some changes when it is time for your next
              appointment. We made these changes to help protect our patients
              and staff. For example:
            </Typography>
            <br />
            <Typography gutterBottom>
              <li className={classes.li}>
                You will be asked some screening questions when you are in the
                office and we will take your temperature upon arrival.
              </li>
              <li className={classes.li}>
                We have hand sanitizer that we will ask you to use when you
                enter the office. You will also find some in the reception area
                and other places in the office for you to use as needed.
              </li>
              <li className={classes.li}>
                Appointments will be managed to allow for social distancing
                between patients. That might mean that you are offered fewer
                options for scheduling your appointments.
              </li>
              <li className={classes.li}>
                We will do our best to allow greater time between patients to
                reduce waiting times for you, as well as to reduce the number of
                patients in the reception area at any one time. It is important
                to arrive on time. Otherwise you will need to be rescheduled due
                to social distancing efforts. Unless necessary, please do not
                bring family or friends inside the office. If you must have
                someone accompany you, it is required that we screen them for
                COVID-19 as well.
              </li>
            </Typography>
            <br />
            <Typography gutterBottom>
              We look forward to seeing you again and are happy to answer any
              questions you may have about the steps we take to keep you, and
              every patient, safe in our practice. To make an appointment,
              please call our office at 973-887-2436. Thank you for being our
              patient. We value your trust and loyalty and look forward to
              welcoming back our patients, neighbors and friends!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Covid);
