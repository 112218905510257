import React, { Component, Fragment } from 'react';
import { Fab, Grid, AppBar, IconButton, Toolbar } from '@material-ui/core';
import Header from './Header';
import { LocationOn, Phone, Email } from '@material-ui/icons';

const withHeader = (WrappedComponent, options = {}) => {
  const HOC = class extends Component {
    render() {
      let header;
      let marginTop;
      switch (options.styleType) {
        default:
          header = <Header />;
          marginTop = '0';
          break;
      }
      return (
        <Fragment>
          {header}
          <Grid style={{ marginTop, marginBottom: '84px' }}>
            <WrappedComponent {...this.props} />
          </Grid>
          <AppBar
            position="fixed"
            color="secondary"
            style={{ top: 'auto', bottom: 0 }}
          >
            <Toolbar>
              <Grid container justify="center">
                <Grid
                  item
                  container
                  justify="space-evenly"
                  lg={8}
                  md={10}
                  xs={12}
                >
                  <Grid item>
                    <a href="tel:973.887.2436">
                      <IconButton color="primary" aria-label="open drawer">
                        <Phone />
                        {/* 973.887.2436 */}
                      </IconButton>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="mailto:info@reinharddentistry.com"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <IconButton color="primary" aria-label="open drawer">
                        <Email />
                        {/* info@reinharddentistry.com */}
                      </IconButton>
                    </a>
                  </Grid>

                  <Grid item>
                    <a
                      href="https://maps.google.com?daddr=130+whippany+Road+Whippany+NJ+07981"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <IconButton
                        // size="small"
                        color="primary"
                        aria-label="open drawer"
                      >
                        <LocationOn />
                        {/* 130 Whippany Rd. Whippany, NJ */}
                      </IconButton>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      style={{ display: 'block', textDecoration: 'none' }}
                      href="https://www.zocdoc.com/practice/reinhard-dentistry-46762?lock=true&isNewPatient=false&referrerType=widget"
                      class="zd-plugin"
                      data-type="book-button"
                      data-practice-id="46762"
                      title="Reinhard Dentistry"
                    >
                      <Fab
                        variant="extended"
                        style={{
                          zIndex: 8000,
                          backgroundColor: '#fffd6b'
                        }}
                      >
                        Book Online Now
                      </Fab>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Fragment>
      );
    }
  };
  return HOC;
};

export default withHeader;
