import React, { Component } from 'react';

import { Map, GoogleApiWrapper } from 'google-maps-react';
import { Rating } from '@material-ui/lab';
import { Avatar, Button, Typography, Grid } from '@material-ui/core';

class ReviewContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fetchPlaces = this.fetchPlaces.bind(this);
  }
  fetchPlaces(mapProps, map) {
    const { google } = mapProps;
    const service = new google.maps.places.PlacesService(map);
    const that = this;
    service.getDetails(
      {
        placeId: 'ChIJ1-WNqMynw4kRkxrqLfuOI9I'
      },
      function(place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          that.setState({ reviews: place.reviews });
          // Intended behavior is to set this.setState({places.place.reviews})
        }
      }
    );
    // ...
  }
  render() {
    const jsx = [];
    const { reviews } = this.state;
    if (reviews) {
      reviews.forEach((review, index) => {
        const {
          author_name,
          rating,
          profile_photo_url,
          text,
          relative_time_description
        } = review;
        jsx.push(
          <Grid
            container
            alignItems="flex-start"
            alignContent="flex-start"
            item
            spacing={1}
            lg={6}
            xs={12}
          >
            <Grid container alignItems="center" item xs={12} spacing={2}>
              <Grid item>
                <Avatar alt={author_name} src={profile_photo_url} />
              </Grid>
              <Grid item>
                <Typography variant="h5">{author_name}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} alignItems="flex-start">
              <Grid item>
                <Rating
                  style={{ height: '1em', marginTop: '2px' }}
                  size="small"
                  readOnly
                  value={rating}
                ></Rating>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {relative_time_description}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>{text}</Typography>
            </Grid>
            {/* {index !== reviews.length - 1 ? (
					<Grid item xs={12}>
						<Divider></Divider>
					</Grid>
        ) : null} */}
          </Grid>
        );
      });
    }
    return (
      <Grid item>
        <Map
          containerStyle={{ position: 'relative' }}
          style={{ position: 'relative' }}
          google={this.props.google}
          onReady={this.fetchPlaces}
          visible={false}
        >
          <Grid container spacing={5}>
            {jsx}
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                onClick={() => {
                  window.open(
                    'https://www.google.com/search?q=reinhard+dentistry+reviews&amp;oq=reinhard+dentistry+reviews&amp;aqs=chrome.0.69i59j69i57j69i60l3.3179j0j4&amp;sourceid=chrome&amp;es_sm=91&amp;ie=UTF-8#lrd=0x89c3a7cca88de5d7:0xd2238efb2dea1a93,1,,,',
                    '_blank'
                  );
                }}
                color="primary"
                variant="outlined"
              >
                See All Reviews
              </Button>
            </Grid>
          </Grid>
        </Map>
      </Grid>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCMDyXbsGx_0lUuftQnAQNl664Dlz7muPw '
})(ReviewContent);
