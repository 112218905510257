import React, { Component } from 'react';
import { Icon, Typography, Grid, Divider } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  logoHt: {
    maxHeight: '40px'
  },
  icon: {
    color: 'rgba(0,0,0,.5)',
    margin: '.16em',

    fontSize: '1.75em  '
  }
});

class Insurance extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="center">
        <Grid
          item
          container
          alignContent="center"
          alignItems="center"
          justify="center"
          lg={9}
          md={10}
          xs={12}
          spacing={5}
        >
          <Grid container item justify="center" spacing={4}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                style={{ textAlign: 'center', marginTop: '1em' }}
                variant="h2"
              >
                Financial Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                We Accept:
              </Typography>
              <Grid
                style={{ marginTop: '.5em' }}
                item
                container
                justify="center"
                spacing={2}
              >
                <Grid item style={{ textAlign: 'center' }}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: 'center' }}
                  >
                    Cash
                  </Typography>
                  <Icon className={classes.icon}>
                    <i class="fas fa-money-bill-wave"></i>
                  </Icon>
                </Grid>
                <Grid item>
                  <Divider
                    style={{ height: '100%' }}
                    orientation="vertical"
                  ></Divider>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: 'center' }}
                  >
                    Credit Card
                  </Typography>
                  <Icon className={classes.icon}>
                    <i class="fab fa-cc-visa"></i>
                  </Icon>
                  <Icon className={classes.icon}>
                    <i class="fab fa-cc-discover"></i>
                  </Icon>
                  <Icon className={classes.icon}>
                    <i class="fab fa-cc-mastercard"></i>
                  </Icon>
                  <Icon className={classes.icon}>
                    <i class="fab fa-cc-amex"></i>
                  </Icon>
                </Grid>
                <Grid item>
                  <Divider
                    style={{ height: '100%' }}
                    orientation="vertical"
                  ></Divider>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{ textAlign: 'center' }}
                  >
                    Check
                  </Typography>
                  <Icon className={classes.icon}>
                    <i class="fas fa-money-check"></i>
                  </Icon>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Flexible Spending Accounts:</Typography>
              <Typography
                style={{ paddingRight: '2em', paddingLeft: '2em' }}
                variant="body1"
              >
                Check to see whether your employer offers a medical spending
                account. If you have a medical spending account, you have
                already put away pre-tax income to use towards uncovered medical
                expenses. Cosmetic Dentistry is one of the medical fields that
                might not be covered by your dental insurance, but would be
                covered now by your spending account.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Financial Plans:</Typography>
              <Typography
                style={{ paddingRight: '2em', paddingLeft: '2em' }}
                variant="body1"
              >
                We are committed to helping our patients afford premier dental
                care by agreeing to allow payment plans under select cases. We
                understand that the cost of dental care can add up, therefore
                for certain cosmetic and bridgework cases, we will break up the
                total cost of treatment over a few months. Please contact us to
                learn more about any of the financing plans mentioned above.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Insurance);
