import React from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Typography
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

function renderServices() {
  const oData = [
    {
      cat: 'Preventative & Diagnostic',
      info: [
        {
          name: 'Comprehensive Evaluation / Oral Examination',
          details: [
            'An oral examination is a visual inspection of the mouth, head, and neck, performed to detect abnormalities.  Radiographs allow for a more complete examination, helping the doctor to detect cavities, problems in existing dental restorations, gum and bone recession or other abnormal conditions within the mouth, head and neck area.'
          ]
        },
        {
          name: 'Cleaning',
          details: [
            'A dental cleaning, also known as an oral prophylaxis, is the removal of dental plaque and tartar (calculus) from the teeth. Specialized instruments are used to gently remove these deposits without harming the teeth. First, an ultrasonic device that emits vibrations and is cooled by water is used to loosen larger pieces of tartar. Next, hand tools are used to manually remove smaller deposits and smooth the tooth surfaces. Once all the tooth surfaces have been cleaned of tartar and plaque, the teeth are polished.'
          ]
        },
        {
          name: 'Custom Mouth Guards',
          details: [
            'Many sports require athletes to use a Mouth Guard to protect their teeth while practicing and playing. Frequently used in contact sports, the mouth guard covers the gums and teeth to provide protection for lips, gums, teeth and arches. A properly fitted mouth guard can reduce the severity of dental injuries.',
            'Pre-formed, ready-to-wear mouth guards can be purchased inexpensively at many sporting stores, but they do not always fit well. They can be uncomfortable and interfere with talking and even breathing.',
            'Sporting goods stores also offer semi-custom mouth guards, in which the plastic is heated and then the athlete bites on it while still warm to provide some level of custom fit.',
            'Your dentist can make a custom mouth guard by taking an impression of your teeth and then creating a device fitted to your mouth for the highest level of both comfort and protection. A custom fitted mouthguard can be particularly important for athletes who wear braces.',
            'Be sure to bring your mouthguard to your dental appointments so your doctor can be sure it still fits you well.'
          ]
        }
      ]
    },
    {
      cat: 'Cosmetic',
      info: [
        {
          name: 'Whitening',
          details: [
            'Whitening, also known as bleaching, is the procedure used to brighten teeth. There are two different ways to achieve a desired whiter smile: In-Office Bleaching and At-Home Bleaching.',
            'In-Office Bleaching usually requires only one office visit. A protective gel or a rubber shield is placed over the gums to protect the soft tissue. A bleaching agent containing carbamide peroxide is applied to the teeth, and a laser light is then used to enhance the action of the whitening agent.',
            'At-Home bleaching requires an impression of the teeth to be taken to make a customized mouth guard to hold the whitening gel, containing carbide peroxide, against the teeth. Once the mouth guard is made, it is worn for a period of time, as instructed by our office. The amount of time may vary from a couple hours a day, or all night, to up to four weeks or longer, if desired.s'
          ]
        },
        {
          name: 'Bonding',
          details: [
            'Cosmetic bonding is the process of filling or restoring teeth with a tooth-colored material in order to maintain a natural appearance. In order to bond a tooth, tooth colored material is added to the tooth to build it up.'
          ]
        },
        {
          name: 'Inlays',
          details: [
            'An inlay or onlay is a partial crown restoration that can be placed when there is not sufficient tooth structure to support a filling, but not quite large enough to require a crown. They are made of a composite or porcelain that aesthetically and functionally replaces the missing tooth structure.'
          ]
        },
        {
          name: 'Invisalign®',
          details: [
            'Invisalign® takes a modern approach to straightening teeth, using a custom-made series of aligners created for you and only you. These aligner trays are made of smooth, comfortable and virtually invisible plastic that you wear over your teeth. Wearing the aligners will gradually and gently shift your teeth into place, based on the exact movements your dentist or orthodontist plans out for you. There are no metal brackets to attach and no wires to tighten. You just pop in a new set of aligners approximately every two weeks, until your treatment is complete. You’ll achieve a great smile with little interference in your daily life. The best part about the whole process is that most people won’t even know that you’re straightening your teeth.'
          ]
        },
        {
          name: 'Veneers',
          details: [
            'Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth. To place a veneer, a very small amount of the original tooth enamel must be removed. Afterwards, an adhesive layer is placed between the slightly prepped tooth and the veneer. The veneer is then hardened with a curing light.'
          ]
        },
        {
          name: 'Dental Implants',
          details: [
            'Dental implants are the replacement of tooth roots in the mouth. Implants provide a strong foundation for fixed or removable replacement teeth. Dental implants, which are placed in the jawbone, are small anchors made of a biocompatible metal called titanium. The anchors begin to fuse with the bone over the course of a few months. After the fusing process, known as osseointegration, abutment posts are inserted into the anchors to allow for the permanent attachment of the replacement teeth.'
          ]
        }
      ]
    },
    {
      cat: 'Restorative',
      info: [
        {
          name: 'Composite Fillings',
          details: [
            'Tooth-colored fillings are the most lifelike material used to fill cavities. Composite fillings can be done in one visit. Once the decay is removed from the tooth, it is filled with this composite material and left to harden immediately afterwards.'
          ]
        },
        {
          name: 'Crowns',
          details: [
            'A crown is a dental restoration that completely covers the outside of a severely decayed tooth. Dental crowns can be completed in two visits. During the first visit, the tooth is prepped (shaved down) and an impression is taken. A temporary crown is placed while the permanent crown is being made. During the second visit, the permanent crown is carefully fitted and then cemented into place for a permanent restoration.'
          ]
        },
        {
          name: 'Bridges',
          details: [
            'A bridge replaces missing teeth without the use of a denture or dental implant. A false tooth is held in place by being attached to a neighboring tooth. In order to fabricate a bridge, crowns are made for the teeth on either side of the space and a false tooth is placed in between the crowns, which act as supports.'
          ]
        },
        {
          name: 'Dentures ',
          details: [
            'A denture is a removable replacement for missing teeth. There are two types of dentures: Complete (Full) and Partial Dentures.',
            'Complete (Full) Dentures are made after all the teeth in the upper and/or lower jaw have been removed and the gum tissue has healed. The denture includes an acrylic base that is custom made in the dental laboratory and is made to look like natural gums. The denture sits over the gums or can be anchored to dental implants.',
            'Removable Partial Dentures are made when only a few teeth need to be replaced. Removable partial dentures either attach to crowns on either side of the denture or to a metal framework that is then attached to the teeth on both sides of the partial denture. When worn during the day, removable partial implants can also be supported in place by dental implants. As opposed to being removable, partial dentures can also be anchored in place by being attached to dental implants.'
          ]
        },
        {
          name: 'Root Canals',
          details: [
            'Root canals are most often necessary when decay has reached the nerve of the tooth or the tooth has become infected.  When root canal therapy is performed, infected or inflamed pulp is removed from the tooth chamber. The inside of the tooth is then cleaned and disinfected before being filled and sealed to limit the possibility of future infection.  Soon after the root canal is performed, the tooth is restored with a dental crown or filling to protect the tooth and restore normal tooth function.'
          ]
        },
        {
          name: 'Extractions',
          details: [
            'A tooth that can not be saved with restorative materials may need to be removed. Before removing your tooth, the area will be numbed with anesthesia. The tooth is then loosened using a special dental instrument known as an elevator. After it is loosened from the socket, it is gently removed by forceps, a dental instrument commonly used in dental extractions. Stitches may be necessary after the removal of a tooth.'
          ]
        }
      ]
    }
  ];
  const returnObj = [];
  oData.forEach((data, index) => {
    const { cat, info } = data;
    const jsx = [];
    const category = (
      <Grid style={{ marginTop: '1em' }} item xs={12}>
        <Typography variant="h4">{cat}</Typography>
      </Grid>
    );
    info.forEach(oInfo => {
      const sDetails = [];
      const { name, details } = oInfo;
      details.forEach(sDetail => {
        sDetails.push(<Typography gutterBottom>{sDetail}</Typography>);
      });
      jsx.push(
        <Grid item md={6} xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{name}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>{details}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      );
    });
    returnObj.push([category, jsx]);
  });
  return returnObj;
}

const Home = () => (
  <Grid container justify="center">
    <Grid
      item
      container
      alignContent="center"
      alignItems="center"
      justify="center"
      lg={9}
      md={10}
      xs={12}
      spacing={3}
    >
      <Grid container item justify="center" spacing={5}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            style={{ textAlign: 'center', marginTop: '1em' }}
            variant="h2"
          >
            Services
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          {renderServices()}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default Home;
