import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({});

class Implants extends Component {
  render() {
    return (
      <Grid container justify="center">
        <Grid
          item
          container
          alignContent="center"
          alignItems="center"
          justify="center"
          lg={9}
          md={10}
          xs={12}
          spacing={5}
        >
          <Grid container item justify="center">
            <Grid item xs={12}>
              <Typography
                gutterBottom
                style={{ textAlign: 'center', marginTop: '1em' }}
                variant="h2"
              >
                Dental Implants
              </Typography>
            </Grid>
            <Grid md={10} xs={12}>
              <Typography gutterBottom variant="body1">
                Dental implants are the replacement of tooth roots in the mouth.
                Implants provide a strong foundation for fixed or removable
                replacement teeth. Dental implants, which are placed in the
                jawbone, are small anchors made of a biocompatible metal called
                titanium. The anchors begin to fuse with the bone over the
                course of a few months. After the fusing process, known as
                osseointegration, abutment posts are inserted into the anchors
                to allow for the permanent attachment of the replacement teeth.
              </Typography>
              <Typography gutterBottom variant="body1">
                Contact us with any questions you have about getting started
                with Dental Implants.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Implants);
