import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  blockquote: {
    fontSize: '1.5em',
    fontStyle: 'italic',
    margin: '0.25em 0',
    padding: '0.2em 32px',
    lineHeight: '1.45',
    color: 'rgba(0,0,0,.5)'
  },
  cite: {
    color: 'rgba(0,0,0,.5)',
    padding: '32px'
  },
  bigQuote: {
    fontFamily: 'Sorts Mill Goudy, serif',
    display: 'block',
    paddingLeft: '10px',
    content: '"',
    marginLeft: '-40px',
    fontSize: '50px',
    position: 'absolute',
    marginTop: '-12px',
    color: 'rgba(0,0,0,.5)'
  }
});

class Invisalign extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="center">
        <Grid
          item
          container
          alignContent="center"
          alignItems="center"
          justify="center"
          lg={9}
          md={10}
          xs={12}
          spacing={5}
        >
          <Grid container item justify="center">
            <Grid item xs={12}>
              <Typography
                gutterBottom
                style={{ textAlign: 'center', marginTop: '1em' }}
                variant="h2"
              >
                Invisalign
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginTop: '4em', marginBottom: '4em' }}
            >
              <Typography
                style={{ textAlign: 'center' }}
                className={classes.blockquote}
              >
                <span className={classes.bigQuote}>"</span>The clear alternative
                to braces...
              </Typography>
            </Grid>
            <Grid md={10} xs={12}>
              <Typography gutterBottom variant="body1">
                Using a series of custom-made aligners, created from smooth,
                nearly invisible, clear plastic, Invisalign® gently shifts your
                teeth into the desired position. Since there are no metal
                brackets or wires, you will not experience the cuts, scrapes,
                and pokes that are common with braces. You can pop out your
                aligner to eat your favorite foods, and to brush and floss your
                teeth as normal. Best of all, you will change aligners at home
                every two weeks or as directed, and only need to come into the
                office once every four to six weeks.
              </Typography>
              <Typography gutterBottom variant="body1">
                Contact us with any questions you have about getting started
                with Invisalign.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Invisalign);
