import React, { Component } from 'react';
import { Typography, Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import ReviewContent from './ReviewContent';

const styles = () => ({
  blockquote: {
    fontSize: '1.5em',
    fontStyle: 'italic',
    margin: '0.25em 0',
    padding: '0.2em 32px',
    lineHeight: '1.45',
    color: 'rgba(0,0,0,.5)'
  },
  cite: {
    color: 'rgba(0,0,0,.5)',
    padding: '32px'
  },
  bigQuote: {
    fontFamily: 'Sorts Mill Goudy, serif',
    display: 'block',
    paddingLeft: '10px',
    content: '"',
    fontSize: '50px',
    position: 'absolute',
    marginTop: '-10px',
    color: 'rgba(0,0,0,.5)'
  }
});

class Reviews extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="center" spacing={5}>
        <Grid
          item
          container
          alignContent="center"
          alignItems="center"
          justify="center"
          lg={9}
          md={10}
          xs={12}
          spacing={5}
        >
          <Grid container item justify="center">
            <Grid item xs={12}>
              <Typography
                style={{ textAlign: 'center', marginTop: '1em' }}
                variant="h2"
              >
                Reviews
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ marginTop: '4em', marginBottom: '4em' }}>
              <span className={classes.bigQuote}>"</span>
              <Typography className={classes.blockquote}>
                I have never waited for an appointment at Reinhard Dentistry.
                They are kind, courteous and take care of YOU, not just your
                mouth...
              </Typography>
              <Typography className={classes.cite} variant="caption">
                &mdash;Beth B.
              </Typography>
            </Grid>
            <ReviewContent></ReviewContent>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Reviews);
