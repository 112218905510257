import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import {
  AppBar,
  Grid,
  Hidden,
  Toolbar,
  Drawer,
  ListItem,
  ListItemText,
  List,
  Button,
  IconButton,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Menu } from '@material-ui/icons';
import Contact from './Contact';

const menuItems = [
  {
    label: 'About Us',
    path: '/about',
  },
  {
    label: 'New Patient',
    path: '/new-patient',
  },
  {
    label: 'Services',
    path: '/services',
  },
  {
    label: 'Reviews',
    path: '/reviews',
  },
  {
    label: 'Insurance',
    path: '/insurance',
  },
  {
    label: 'Financial',
    path: '/financial',
  },
];

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '20px',
  },
  list: { minWidth: '250px' },
  menuButton: {
    borderRadius: '0',
    height: '100%',
    marginRight: theme.spacing(3),
    color: '#fff',
    textTransform: 'none',
  },
  title: {
    flexGrow: 1,
  },
  navButton: {
    borderRadius: 0,
    paddingLeft: '24px',
    paddingRight: '24px',
    height: '100%',
    fontWeight: 300,
    alignItems: 'center',
    textTransform: 'none',
  },
  navContainer: {
    margin: 0,
    height: '100%',
  },
});

function renderMenuItems(classes) {
  const jsx = [];
  menuItems.forEach((item) => {
    jsx.push(
      <Button
        key={`menuItem-desktop-${item.path}`}
        className={classes.menuButton}
        component={Link}
        to={item.path}
      >
        {item.label}
      </Button>
    );
  });
  return jsx;
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { contactDialogOpen: false, drawerOpen: false };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }
  toggleDrawer(event) {
    const { drawerOpen } = this.state;
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({ drawerOpen: !drawerOpen });
  }
  render() {
    const { classes } = this.props;
    const { drawerOpen } = this.state;
    return (
      <Fragment>
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <Grid
                container
                justify="center"
                style={{ width: '100%', height: '64px' }}
                spacing={0}
              >
                <Grid item container justify="space-between" xl={10} xs={12}>
                  <Grid
                    item
                    style={{ paddingTop: '15px' }}
                    align-content="center"
                  >
                    <Link to="/" className="no-select">
                      <img
                        width="60px"
                        alt="logo"
                        src="https://res.cloudinary.com/dwelloptimal/image/upload/v1571777290/reinhard-dentistry/logo_white_20191021.png"
                      />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Hidden smDown>
                      {renderMenuItems(classes)}
                      <Button
                        style={{
                          height: '3.5em',
                          borderRadius: '100px',
                          textTransform: 'none',
                          fontWeight: '100',
                        }}
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                          this.setState({ contactDialogOpen: true });
                        }}
                      >
                        Contact Us
                      </Button>
                    </Hidden>
                    <Hidden mdUp>
                      <IconButton
                        style={{ marginTop: '8px' }}
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={this.toggleDrawer}
                      >
                        <Menu />
                      </IconButton>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Drawer open={drawerOpen} anchor="right" onClose={this.toggleDrawer}>
            <div
              className={classes.list}
              role="presentation"
              onClick={this.toggleDrawer}
              onKeyDown={this.toggleDrawer}
            >
              <List>
                {menuItems.map((item, index) => (
                  <ListItem
                    button
                    component={Link}
                    to={item.path}
                    key={item.label}
                  >
                    {/* <ListItemIcon>
                  </ListItemIcon> */}
                    <ListItemText primary={item.label} />
                  </ListItem>
                ))}
                <ListItem
                  button
                  onClick={() => {
                    this.setState({ contactDialogOpen: true });
                  }}
                  key="Contact Us"
                >
                  {/* <ListItemIcon>
                  </ListItemIcon> */}
                  <ListItemText primary="Contact Us" />
                </ListItem>
              </List>
            </div>
          </Drawer>
          <Alert severity="info" to="covid-19" component={Link}>
            <b>
              Read about our continued commitment to the safety of our patients
              and staff as it relates to COVID-19
            </b>
          </Alert>
        </div>
        <Contact parent={this} />
      </Fragment>
    );
  }
}
export default withStyles(styles)(Header);
