import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import {
  Fab,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import ReviewContent from "./ReviewContent";
const styles = (theme) => ({
  card: { margin: ".25em" },
});
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderServices = this.renderServices.bind(this);
  }
  renderAwards() {
    return (
      <Grid item container justify="space-evenly" style={{ marginTop: "0" }}>
        <Grid
          item
          style={{
            textAlign: "center",
            verticalAlign: "center",
            marginTop: "1em",
            height: "10em",
            width: "10em",
            // marginRight: '2em',
            border: "solid #eee 3px",
            borderRadius: "50%",
          }}
        >
          <img
            style={{ marginTop: ".8em", height: "8em" }}
            alt="top dentist 2017"
            src="https://res.cloudinary.com/dwelloptimal/image/upload/v1572035837/reinhard-dentistry/awards/topDentist_2017.png"
          ></img>
        </Grid>
        <Grid
          item
          style={{
            textAlign: "center",
            verticalAlign: "center",
            marginTop: "1em",
            height: "10em",
            width: "10em",
            // marginRight: '2em',
            border: "solid #eee 3px",
            borderRadius: "50%",
          }}
        >
          <img
            style={{ marginTop: ".8em", height: "8em" }}
            alt="top dentist 2018"
            src="https://res.cloudinary.com/dwelloptimal/image/upload/v1572035837/reinhard-dentistry/awards/topDentist_2018.png"
          ></img>
        </Grid>
        <Grid
          item
          style={{
            textAlign: "center",
            verticalAlign: "center",
            marginTop: "1em",
            height: "10em",
            width: "10em",
            border: "solid #eee 3px",
            borderRadius: "50%",
          }}
        >
          <img
            style={{ marginTop: ".8em", height: "8em" }}
            alt="top dentist 2019"
            src="https://res.cloudinary.com/dwelloptimal/image/upload/v1572035837/reinhard-dentistry/awards/topDentist_2019.png"
          ></img>
        </Grid>
        <Grid
          item
          style={{
            textAlign: "center",
            verticalAlign: "center",
            marginTop: "1em",
            height: "10em",
            width: "10em",
            border: "solid #eee 3px",
            borderRadius: "50%",
          }}
        >
          <img
            style={{ marginTop: ".8em", height: "8em" }}
            alt="top dentist 2020"
            src="https://res.cloudinary.com/dwelloptimal/image/upload/v1583160615/reinhard-dentistry/awards/NewJerseyDentistBadge2020.png"
          ></img>
        </Grid>
      </Grid>
    );
  }
  renderServices() {
    const { classes } = this.props;
    const jsx = [];
    const services = [
      {
        id: 1,
        title: "Services Overview",
        path: "/services",
        // shortDescription: ''
        description: `Invisalign® takes a modern approach to straightening teeth, using a custom-made series of aligners (clear trays). As these aligners are worn, your teeth will gradually move towards their ideal position! Each tray is worn for a period of around two weeks, until your trays fit passively in the mouth, and your teeth are able to fit into the next tray. There are no unsightly metal brackets or painful wires to tighten as used with braces either!
        With regular braces, you may experience sores, severe plaque buildup and be forced to eliminate a wide variety of foods from your diet. With Invisalign, your trays are removable. Therefore, you can remove your trays before each meal and before brushing your teeth at night. You will be able to eat all of the same foods you would have before treatment and will not have any change in oral hygiene due to wearing the trays either.
        Invisalign effectively treats a wide variety of cases, including crowding, spacing, crossbite, overbite and underbite.  Each treatment is case specific, therefore, the length of each treatment will vary. You can set up a consult with our Invisalign certified dentists today!
        `,
        // imgUrl:
        // 'https://res.cloudinary.com/dwelloptimal/image/upload/v1572036890/reinhard-dentistry/DentalService1.png'
        imgUrl:
          "https://res.cloudinary.com/dwelloptimal/image/upload/v1647696539/reinhard-dentistry/services.jpg",
      },
      {
        id: 2,
        title: "Invisalign",
        path: "/invisalign",
        // shortDescription: ''
        description: `Invisalign® takes a modern approach to straightening teeth, using a custom-made series of aligners (clear trays). As these aligners are worn, your teeth will gradually move towards their ideal position! Each tray is worn for a period of around two weeks, until your trays fit passively in the mouth, and your teeth are able to fit into the next tray. There are no unsightly metal brackets or painful wires to tighten as used with braces either!
        With regular braces, you may experience sores, severe plaque buildup and be forced to eliminate a wide variety of foods from your diet. With Invisalign, your trays are removable. Therefore, you can remove your trays before each meal and before brushing your teeth at night. You will be able to eat all of the same foods you would have before treatment and will not have any change in oral hygiene due to wearing the trays either.
        Invisalign effectively treats a wide variety of cases, including crowding, spacing, crossbite, overbite and underbite.  Each treatment is case specific, therefore, the length of each treatment will vary. You can set up a consult with our Invisalign certified dentists today!
        `,
        imgUrl:
          "https://res.cloudinary.com/dwelloptimal/image/upload/v1572566757/reinhard-dentistry/Invisalign_retainer.jpg",

        // imgUrl:
        //   'https://res.cloudinary.com/dwelloptimal/image/upload/v1571870452/reinhard-dentistry/invisalign_new.jpg'
      },
      {
        id: 3,
        title: "Dental Implants",
        // shortDescription: ''
        path: "/implants",
        description: `Invisalign® takes a modern approach to straightening teeth, using a custom-made series of aligners (clear trays). As these aligners are worn, your teeth will gradually move towards their ideal position! Each tray is worn for a period of around two weeks, until your trays fit passively in the mouth, and your teeth are able to fit into the next tray. There are no unsightly metal brackets or painful wires to tighten as used with braces either!
        With regular braces, you may experience sores, severe plaque buildup and be forced to eliminate a wide variety of foods from your diet. With Invisalign, your trays are removable. Therefore, you can remove your trays before each meal and before brushing your teeth at night. You will be able to eat all of the same foods you would have before treatment and will not have any change in oral hygiene due to wearing the trays either.
        Invisalign effectively treats a wide variety of cases, including crowding, spacing, crossbite, overbite and underbite.  Each treatment is case specific, therefore, the length of each treatment will vary. You can set up a consult with our Invisalign certified dentists today!
        `,
        // imgUrl: 'https://res.cloudinary.com/dwelloptimal/image/upload/v1572018389/reinhard-dentistry/row-of-teeth-with-dental-implant-vector-14763637.jpg'
        imgUrl:
          "https://res.cloudinary.com/dwelloptimal/image/upload/v1572382855/reinhard-dentistry/images.jpg",
      },
      {
        id: 4,
        title: "New Patient",
        path: "/new-patient",
        // shortDescription: ''
        description: `Invisalign® takes a modern approach to straightening teeth, using a custom-made series of aligners (clear trays). As these aligners are worn, your teeth will gradually move towards their ideal position! Each tray is worn for a period of around two weeks, until your trays fit passively in the mouth, and your teeth are able to fit into the next tray. There are no unsightly metal brackets or painful wires to tighten as used with braces either!
        With regular braces, you may experience sores, severe plaque buildup and be forced to eliminate a wide variety of foods from your diet. With Invisalign, your trays are removable. Therefore, you can remove your trays before each meal and before brushing your teeth at night. You will be able to eat all of the same foods you would have before treatment and will not have any change in oral hygiene due to wearing the trays either.
        Invisalign effectively treats a wide variety of cases, including crowding, spacing, crossbite, overbite and underbite.  Each treatment is case specific, therefore, the length of each treatment will vary. You can set up a consult with our Invisalign certified dentists today!
        `,
        imgUrl:
          "https://res.cloudinary.com/dwelloptimal/image/upload/v1572017435/reinhard-dentistry/patient-forms1.jpg",
      },
    ];
    services.forEach((service) => {
      jsx.push(
        <Grid
          key={`serviceCard-${service.id}`}
          item
          lg={3}
          md={3}
          sm={3}
          xs={6}
        >
          <Card className={classes.card}>
            <CardActionArea component={Link} to={service.path}>
              <CardMedia
                style={{ height: "16em" }}
                component="img"
                alt={service.title}
                image={service.imgUrl}
                title={service.title}
              />
              <CardContent style={{ maxHeight: "8em" }}>
                <Typography variant="h5" component="h2">
                  {service.title}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    });
    return jsx;
  }
  render() {
    return (
      <Fragment>
        <Grid container justify="center">
          <Grid
            item
            container
            alignContent="center"
            alignItems="center"
            justify="center"
            lg={9}
            md={10}
            xs={12}
            spacing={5}
          >
            <Grid
              item
              xs={12}
              style={{ paddingTop: "6em", paddingBottom: "2em" }}
            >
              <Grid style={{ textAlign: "center" }} item xs={12}>
                <img
                  style={{ maxWidth: "580px", width: "100%" }}
                  alt="reinhard dentistry logo"
                  src="https://res.cloudinary.com/dwelloptimal/image/upload/v1571856475/reinhard-dentistry/logo_big_blue_est_20191023.png"
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
            <Divider></Divider>
          </Grid> */}
            {/* <Grid item xs={12}>
            <Typography variant="h3">About Us</Typography>
          </Grid> */}
            <Grid item md={6} xs={12} style={{ textAlign: "center" }}>
              <img
                style={{
                  maxWidth: "330px",
                  width: "100%",
                  borderRadius: "8px",
                }}
                alt="tom reinhard richard reinhard headshot"
                src="https://res.cloudinary.com/dwelloptimal/image/upload/v1571857100/reinhard-dentistry/headshot_tom-richard.jpg"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography gutterBottom>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reinhard Dentistry has been
                treating patients continuously since 1918, providing care in
                Whippany, NJ since 1948. The relaxed atmosphere and the time the
                doctors take to explain procedural options are just a few of the
                attributes patients attest to. The practice is conveniently
                located close to Routes 287, 10 and 80, and the office prides
                itself on seeing patients at the time of their appointment. The
                staff will submit all insurance claims for its patients.
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dr. Thomas Reinhard received his
                DMD from the University of Pennsylvania School of Dental
                Medicine in 1981. He is proud to welcome to the practice his
                son, Dr. Richard Reinhard , who received his DMD with
                distinction from Tufts University School of Dental Medicine in
                2015.
              </Typography>
            </Grid>
            {this.renderAwards()}
            <Grid item>
              <div>
                <a
                  style={{ display: "block", textDecoration: "none" }}
                  href="https://www.zocdoc.com/practice/reinhard-dentistry-46762?lock=true&isNewPatient=false&referrerType=widget"
                  class="zd-plugin"
                  data-type="book-button"
                  data-practice-id="46762"
                  title="Reinhard Dentistry"
                >
                  <Fab color="primary" variant="outlined">
                    Book Online Now
                  </Fab>
                </a>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">Patient Services</Typography>
            </Grid>
            <Grid container item={12}>
              {this.renderServices()}
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">Reviews</Typography>
            </Grid>
            <Grid item container xs={12}>
              <ReviewContent></ReviewContent>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(Home);
