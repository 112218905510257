import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";

import { Alert } from "@material-ui/lab";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Snackbar,
  TextField,
  withMobileDialog,
} from "@material-ui/core";
import { LocationOn, Phone, Email } from "@material-ui/icons";

const styles = (theme) => ({
  root: {},
});

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      contactPreference: "email",
      successOpen: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleSubmit() {
    const { email, name, contactPreference, message, phone } = this.state;
    var template_params = {
      reply_to: email,
      from_name: name,
      message_html: message,
      email: email,
      phone: phone,
      contactPreference: contactPreference,
    };

    var service_id = "default_service";
    var template_id = "service_vjkmy2c";
    window.emailjs.send(service_id, template_id, template_params);
    this.handleClose();
    this.setState({ successOpen: true });
  }
  handleClose() {
    const { parent } = this.props;
    parent.setState({ contactDialogOpen: false });
  }
  handleChange(event) {
    const { target } = event;
    this.setState({ [target.name]: target.value });
  }
  render() {
    const { parent, fullScreen } = this.props;
    const { contactPreference, email, name, message, successOpen } = this.state;
    return (
      <Fragment>
        <Dialog
          fullScreen={fullScreen}
          open={parent.state.contactDialogOpen}
          onClose={this.handleClose}
        >
          <DialogTitle id="form-dialog-title">Get in touch!</DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ textAlign: "center", marginBottom: "1em" }}
            >
              <LocationOn
                style={{
                  fontSize: "1em",
                  marginBottom: "-2px",
                  marginRight: "4px",
                }}
              />
              130 Whippany Rd. Whippany, NJ
              <br />
              <Email
                style={{
                  fontSize: "1em",
                  marginBottom: "-2px",
                  marginRight: "4px",
                }}
              />
              info@reinharddentistry.com
              <br />
              <Phone
                style={{
                  fontSize: "1em",
                  marginBottom: "-2px",
                  marginRight: "4px",
                }}
              />
              973.887.2436
            </DialogContentText>
            <DialogContentText>
              Please reach out with any questions and our staff will get back to
              you shortly.
            </DialogContentText>
            <TextField
              autoFocus
              required={true}
              id="email"
              label="Email Address"
              type="email"
              name="email"
              margin="normal"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              required={true}
              id="name"
              label="Name"
              name="name"
              margin="normal"
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              id="phone"
              label="Phone Number"
              type="tel"
              name="phone"
              margin="normal"
              onChange={this.handleChange}
              fullWidth
            />
            <FormControl
              style={{ marginTop: "1em", marginBottom: "1em" }}
              component="fieldset"
            >
              <RadioGroup
                aria-label="contactPreference"
                name="contactPreference"
                value={contactPreference}
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="email"
                  control={<Radio color="primary" />}
                  label="Email Preferred"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="phone"
                  control={<Radio color="primary" />}
                  label="Phone Preferred"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="nopreference"
                  control={<Radio color="primary" />}
                  label="No Preference"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              required={true}
              multiline={true}
              margin="normal"
              id="message"
              label="Message"
              type="message"
              name="message"
              onChange={this.handleChange}
              rows={5}
              rowsMax={20}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} size="large" color="primary">
              Close
            </Button>
            <Button
              disabled={email === "" || name === "" || message === ""}
              onClick={this.handleSubmit}
              color="primary"
              size="large"
              variant="contained"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={successOpen}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ successOpen: false });
          }}
        >
          <Alert severity="success" variant="filled">
            Message successfully sent. We'll get back to you shortly!
          </Alert>
        </Snackbar>
      </Fragment>
    );
  }
}
export default withMobileDialog()(withStyles(styles)(Contact));
