import React, { Component } from 'react';
import { Fab, Typography, Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({});

class NewPatient extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const { target } = event;
    this.setState({ [target.name]: target.value });
  }
  render() {
    return (
      <Grid container justify="center">
        <Grid
          item
          container
          alignContent="center"
          alignItems="center"
          justify="center"
          lg={9}
          md={10}
          xs={12}
        >
          <Grid container item justify="center">
            <Grid item xs={12}>
              <Typography
                gutterBottom
                style={{ textAlign: 'center', marginTop: '1em' }}
                variant="h2"
              >
                Welcome to Reinhard Dentistry
              </Typography>
            </Grid>
            <Grid
              item
              style={{ textAlign: 'center', margin: '.5em 0 1em' }}
              xs={12}
            ></Grid>
            <Grid item md={9} xs={11}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ textAlign: 'center' }}
              >
                Please download and fill out the new patient form to save time
                at your next appointment. If you still need to book an
                appointment, contact us or book online by clicking the button
                below.
              </Typography>
            </Grid>
            <Grid
              style={{
                textAlign: 'center',
                marginTop: '1em',
                marginBottom: '1em'
              }}
              item
              xs={12}
              justify="center"
              container
              spacing={4}
            >
              <Grid item>
                <a
                  style={{ display: 'block', textDecoration: 'none' }}
                  href="https://res.cloudinary.com/dwelloptimal/image/upload/v1582244479/reinhard-dentistry/Patient-Registration-Form-min.pdf"
                  class="zd-plugin"
                  data-type="book-button"
                  data-practice-id="46762"
                  title="New Patient Form"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Fab color="secondary" variant="outlined">
                    Download New Patient Form
                  </Fab>
                  {/* <img
                  src="https://offsiteSchedule.zocdoc.com/images/remote/zd_bookonline_162x48.png"
                  alt="Reinhard Dentistry"
                  title="Reinhard Dentistry"
                  style={{ border: 0 }}
                /> */}
                </a>
              </Grid>
              <Grid item>
                <a
                  style={{ display: 'block', textDecoration: 'none' }}
                  href="https://www.zocdoc.com/practice/reinhard-dentistry-46762?lock=true&isNewPatient=false&referrerType=widget"
                  class="zd-plugin"
                  data-type="book-button"
                  data-practice-id="46762"
                  title="Reinhard Dentistry"
                >
                  <Fab color="primary" variant="outlined">
                    Book Online Now
                  </Fab>
                </a>
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: '2em', backgroundColor: '#fff' }}
              item
              container
              spacing={3}
              xs={12}
            ></Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(NewPatient);
