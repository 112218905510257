import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import { MuiThemeProvider } from '@material-ui/core/styles';

import Home from './components/Home';
import Services from './components/Services';
import Reviews from './components/Reviews';
import Insurance from './components/Insurance';
import Financial from './components/Financial';
import Covid from './components/Covid';
import NewPatient from './components/NewPatient';
import Invisalign from './components/Invisalign';
import Implants from './components/Implants';

import theme from './theme.js';

import withTracker from './components/withTracker';
import withHeader from './components/withHeader';

const App = () => (
  <div className="App">
    <Helmet>
      <title>Reinhard Dentistry</title>
      <meta
        name="description"
        content="Providing Comprehensive Family, Emergency, Cosmetic and Restorative Dentistry in Whippany, NJ since 1948."
      />
    </Helmet>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            component={withHeader(
              withTracker(Home, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/about"
            component={withHeader(
              withTracker(Home, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/covid-19"
            component={withHeader(
              withTracker(Covid, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/insurance"
            component={withHeader(
              withTracker(Insurance, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/financial"
            component={withHeader(
              withTracker(Financial, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/about"
            component={withHeader(
              withTracker(Home, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/services"
            component={withHeader(
              withTracker(Services, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/invisalign"
            component={withHeader(
              withTracker(Invisalign, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/new-patient"
            component={withHeader(
              withTracker(NewPatient, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/implants"
            component={withHeader(
              withTracker(Implants, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
          <Route
            path="/reviews"
            component={withHeader(
              withTracker(Reviews, {
                /* additional GA attributes */
              }),
              {
                styleType: 1, // slick homepage header
                /* additional GA attributes */
              }
            )}
          />
        </Switch>
      </Router>
    </MuiThemeProvider>
  </div>
);

export default App;
