// import 'babel-polyfill';
import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
  // use as reference for theme below
  typography: {
    useNextVariants: true // necessary to bipass variant outdated warning
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiButton: {},
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'rgb(245,245,245,.9)'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8  px'
      },
      elevation1: {
        boxShadow: 'none',
        border: 'solid 1px #eee'
      }
    },
    MuiTypography: {
      h1: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '2.5rem'
        },
        color: 'rgba(0, 0, 0, 0.75)',
        fontSize: '4rem',
        fontWeight: '500',
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
      },
      h2: {
        // textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.75)',
        // background: '#444',
        // padding: '1rem',
        fontSize: '2rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '2rem'
        },
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
      },
      h3: {
        color: 'rgba(0, 0, 0, 0.75)',
        fontSize: '1.5rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '1.5rem'
        },
        fontWeight: '300',
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
      },
      h4: {
        color: 'rgba(0, 0, 0, 0.75)',
        fontSize: '1.25rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '1rem'
        },
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
      },
      h5: {
        color: 'rgba(0, 0, 0, 0.75)',
        fontSize: '1rem',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '1rem'
        },
        fontFamily: ['Montserrat', 'sans-serif'].join(',')
      },
      body1: {
        fontFamily: ['Lato', 'sans-serif'].join(','),
        color: 'rgba(0, 0, 0, 0.7)'
      },
      body2: {
        fontFamily: ['Lato', 'sans-serif'].join(','),
        color: 'rgba(0, 0, 0, 0.5)',
        // color: 'red',
        fontSize: '0.875rem',
        // fontWeight: '100',
        lineHeight: '1.5'
      }
    }
  },
  palette: {
    text: {
      primary: 'rgba(0, 0, 0, 0.75)',
      secondary: 'rgba(0,0,0,.5)'
    },
    primary: {
      // main: '#226E72'
      // main: '#47A29C' //teal
      // main: 'rgb(0, 106, 112)' //darker teal
      // main: '#15304C'
      main: '#122433'
    }, // rgb(71,162,156)
    secondary: {
      // main: '#FFAE70'
      // main: '#91584A'
      // main: '#C79859',
      main: '#fff'
      // main: '#fff870'
    },
    success: {
      light: '#87C46E',
      main: '#219e0e',
      dark: '#266E09'
    },
    error: {
      main: '#7F2B23',
      light: '#e96363'
    },
    neutral: {
      main: 'darkgoldenrod'
    }
  },
  typography: {
    useNextVariants: true // https://material-ui.com/style/typography/#migration-to-typography-v2
    // Use the system font instead of the default Roboto font.
    // fontFamily: ['Lato', 'sans-serif'].join(','),
    // fontFamily: ['Work Sans', 'sans-serif'].join(',')
  }
});

export default theme;
